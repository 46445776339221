<template>
  <div class="profileBox" v-if="companyForm" v-loading="loadingUpdating">
    <div class="profileContent">
      <div class="imageBg">
        <div class="textContent" v-if="modeType === 'add'">
          <div class="title">Welcome To Echola!</div>
          <div class="setps">
            <div class="item">
              <div class="index">1</div>
              <div class="content">
                {{ $t("home.Improve personal information") }}
              </div>
            </div>
            <div class="line"></div>
            <div class="item">
              <div class="index">2</div>
              <div class="content">{{ $t("home.Create/Join Company") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contentForm">
        <div class="header">
          <div class="left">
            <div class="leftBox">
              <div class="formImage">
                <el-image :src="logoImage" fit="contain" />
                <div class="mask" v-show="isEdit">
                  <i class="iconfont icon-xiangji"></i>
                  <input type="file" @change="confirmUploadLogo" />
                </div>
              </div>
              <div class="conetnt" v-if="companyPersonnelList.length">
                <div class="companyMoreWrapper">
                  <template v-for="(item, index) in memberListComputed">
                    <member 
                      :info="item" 
                      :key="index"
                      :size="40" 
                      :placement="'bottom'"
                      v-if="index < 3"
                    ></member>
                  </template>
                  <div class="moreImage" @click="onMoreList">
                    <el-image :src="companyPersonnelList[companyPersonnelList.length - 1] && companyPersonnelList[companyPersonnelList.length - 1].avatarFileUrl" />
                    <div class="mask">+{{ companyPersonnelList.length - memberListComputed.length }}</div>
                  </div>
                </div>
              </div>
              <div 
                class="thaliBox" 
                style="margin: 0 0 5px 20px;"
                @click="seeThaliDetail"
                v-if="(companyId == companyForm.companyId) && companyThaliDetail">
                <svg width="28" height="32" xmlns="http://www.w3.org/2000/svg" fill="none">
                  <g>
                    <path id="svg_1" :fill="companyThaliDetail.currentCombo.style" d="m12.9334,0.64966c0.6173,-0.35993 1.3797,-0.36321 2,-0.00862l11.826,6.75989c0.6203,0.35459 1.0044,1.01319 1.0074,1.72772l0.0588,13.62155c0.0031,0.7145 -0.3753,1.3764 -0.9925,1.7363l-11.7673,6.8617c-0.6172,0.3599 -1.3796,0.3632 -1.9999,0.0086l-11.82601,-6.7599c-0.62034,-0.3546 -1.00438,-1.0132 -1.00746,-1.7277l-0.05876,-13.62154c-0.00308,-0.71452 0.37527,-1.37642 0.99253,-1.73635l11.7672,-6.86165z"/>
                    <path stroke="null" fill-opacity="0.6" fill="white" d="m14.16047,20.37036l-7.72438,-3.68976l-0.89669,-3.49605c-0.0515,-0.19826 -0.04896,-0.4067 0.00736,-0.60366c0.0563,-0.19695 0.16433,-0.37522 0.31284,-0.51632c0.14851,-0.14108 0.3321,-0.23982 0.53167,-0.28597c0.19958,-0.04615 0.40788,-0.038 0.60325,0.02359l7.16974,2.23369l7.13761,-2.3518c0.16665,-0.05479 0.34476,-0.06504 0.51661,-0.02975c0.17185,0.03528 0.33153,0.1149 0.46311,0.23093c0.13169,0.11603 0.23067,0.26446 0.28713,0.43055c0.05657,0.1661 0.06874,0.34409 0.0352,0.51631l-0.71622,3.68503l-7.72722,3.85323z"/>
                    <path stroke="null" fill="white" d="m7.84493,21.90886l-1.40881,-5.22894l6.73132,-7.89635c0.11819,-0.13871 0.26486,-0.25036 0.43003,-0.32735c0.16516,-0.077 0.34498,-0.11754 0.52721,-0.11889c0.18223,-0.00135 0.36261,0.03654 0.5289,0.11109c0.16629,0.07456 0.31459,0.18403 0.43481,0.32098l6.79554,7.74801l-1.16126,5.32531c-0.07323,0.33791 -0.26008,0.64039 -0.52925,0.85724c-0.26929,0.21685 -0.60472,0.33472 -0.95043,0.33425l-9.93443,0c-0.334,0.00047 -0.65876,-0.10972 -0.92354,-0.31323c-0.26479,-0.20362 -0.4547,-0.48921 -0.54008,-0.81212z"/>
                    <path stroke="null" fill="#7A7A7A" d="m14.00834,20.74324c-0.1071,0.00024 -0.21303,-0.02232 -0.31078,-0.06614c-0.09774,-0.04382 -0.18504,-0.10795 -0.25614,-0.18803l-2.61069,-2.94805c-0.06583,-0.07446 -0.11635,-0.16114 -0.14868,-0.25512c-0.03233,-0.09397 -0.04583,-0.19339 -0.03972,-0.29258c0.01231,-0.20033 0.1037,-0.38755 0.25407,-0.52049c0.15035,-0.13294 0.34736,-0.20072 0.54769,-0.1884c0.09919,0.00609 0.1962,0.03168 0.28552,0.07526c0.0893,0.04359 0.16915,0.10435 0.23498,0.17881l2.04093,2.30364l2.00314,-2.29891c0.06455,-0.07703 0.14381,-0.14042 0.23315,-0.18646c0.08934,-0.04604 0.18696,-0.0738 0.28714,-0.08165c0.10016,-0.00785 0.2009,0.00433 0.29634,0.03588c0.09543,0.03155 0.18366,0.0818 0.25937,0.14784c0.07583,0.06602 0.1376,0.1465 0.18189,0.23672c0.04429,0.09021 0.07016,0.18835 0.07606,0.28867c0.00591,0.10033 -0.00815,0.20083 -0.04157,0.29562c-0.03343,0.09479 -0.08539,0.18197 -0.15283,0.25645l-2.56818,2.94805c-0.07067,0.08091 -0.15778,0.14598 -0.25554,0.19051c-0.09776,0.04465 -0.20392,0.06803 -0.3114,0.06839l-0.00472,0z"/>
                  </g>
                </svg>
                <div class="textBox" :style="{
                  color: hex2Rgba(companyThaliDetail.currentCombo.style, .9),
                  background: `linear-gradient(90deg, ${hex2Rgba(companyThaliDetail.currentCombo.style, .1)} 0%, ${hex2Rgba(companyThaliDetail.currentCombo.style, .3)} 100%)`,
                }">
                  {{ companyThaliDetail.currentCombo.comboName }}
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-if="(manageCompany && companyId == companyForm.companyId) || (modeType == 'add' || modeType == 'join')">
            <el-button size="small" @click="goBack" v-if="modeType != 'edit' && (modeType == 'add' || modeType == 'join')">
              {{ $t('home.Return personal information') }}
            </el-button>
            <el-button size="small" v-else @click="addDialogVisible = !addDialogVisible">
              {{ $t('people.InvitePeople') }}
            </el-button>
            <el-button type="primary" size="small" @click="editCompanyData">
              {{ modeType == 'add' ? $t('home.createCompany') : modeType == 'join' ? $t('home.Confirma join') : !isEdit ? $t('home.Modify information') : $t('home.Confirmation Information') }}
            </el-button>
          </div>
        </div>
        <div class="formBox">
          <el-form 
            ref="companyFormRef" 
            :rules="rules" 
            :model="companyForm" 
            class="companyForm"
            :class="isEdit ? 'edit' : ''">
            <el-form-item prop="companyCode" class="showRequire" v-if="!company && modeType == 'join'">
              <div class="title">{{ $t('CompanyEdit.CompanyName') }}</div>
              <el-input 
                v-model="companyForm.companyCode" 
                :placeholder="$t('home.Please enter the company invitation code')" 
                @blur="getCodeCompany" 
                @keyup.enter.native="getCodeCompany"
              />
            </el-form-item>
            <el-form-item prop="name" v-else>
              <div class="title">{{ $t('CompanyEdit.CompanyName') }}</div>
              <el-autocomplete
                v-if="isEdit && modeType == 'add'"
                v-model="companyForm.name"
                style="width: 100%"
                :fetch-suggestions="querySearchCompanyAsync"
                :placeholder="$t('home.Please enter')+$t('CompanyEdit.CompanyName')"
                autocomplete="off"
                @select="handleCompanySelect"
              ></el-autocomplete>
              <el-input v-else-if="isEdit" v-model="companyForm.name" :placeholder="$t('home.Please enter')+$t('CompanyEdit.CompanyName')" autocomplete="off" />
              <div v-else class="text line2">{{ companyForm.name }}</div>
            </el-form-item>
            <el-form-item prop="aliasName">
              <div class="title">{{ $t('CompanyEdit.CompanyAlias') }}</div>
              <el-input v-if="isEdit" v-model="companyForm.aliasName" :placeholder="$t('home.Please enter')+$t('CompanyEdit.CompanyAlias')" autocomplete="off" />
              <div v-else class="text line2">{{ companyForm.aliasName }}</div>
            </el-form-item>
            <el-form-item prop="email">
              <div class="title">{{ $t('CompanyEdit.CompanyEmil') }}</div>
              <el-input v-if="isEdit" v-model="companyForm.email" :placeholder="$t('home.Please enter')+$t('CompanyEdit.CompanyEmil')" autocomplete="off" />
              <div v-else class="text line2">{{ companyForm.email }}</div>
            </el-form-item>
            <el-form-item prop="companyType">
              <div class="title">{{ $t('CompanyEdit.Type') }}</div>
              <el-select
                v-model="companyForm.companyType"
                v-if="isEdit"
                appendToBody
                autocomplete="off"
                :disabled="modeType !== 'add'"
                :placeholder="$t('home.Please select')+$t('CompanyEdit.Type')"
              >
                <el-option
                  v-for="item in companyType"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value"
                />
              </el-select>
              <div v-else class="text line2">{{ isFindData(companyType, companyForm.companyType, 'value', 'key') }}</div>
            </el-form-item>
            <el-form-item prop="country">
              <div class="title">{{ $t('CompanyEdit.Country') }}</div>
              <el-select
                v-model="companyForm.country"
                filterable
                appendToBody
                :placeholder="$t('customerInfo.Choose the country')"
                autocomplete="off"
                v-if="isEdit"
              >
                <el-option
                  v-for="item in country"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div v-else class="text line2">{{ isFindData(country, companyForm.country, 'value', 'key') }}</div>
            </el-form-item>
            <el-form-item prop="contact">
              <div class="title">{{ $t('CompanyEdit.Contact') }}</div>
              <el-input v-if="isEdit" v-model="companyForm.contact" :placeholder="$t('home.Please enter')+$t('CompanyEdit.Contact')" autocomplete="off" />
              <div v-else class="text line2">{{ companyForm.contact }}</div>
            </el-form-item>
            <el-form-item prop="vat">
              <div class="title">{{ $t('CompanyEdit.VATNO') }}</div>
              <el-input v-if="isEdit" v-model="companyForm.vat" :placeholder="$t('home.Please enter')+$t('CompanyEdit.VATNO')" autocomplete="off" />
              <div v-else class="text line2">{{ companyForm.vat }}</div>
            </el-form-item>
            <el-form-item prop="address">
              <div class="title address">
                {{ $t('CompanyEdit.Address') }}
                <img v-if="isEdit" @click="mapSelectEvent" src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/selectMap.png" />
              </div>
              <el-input v-if="isEdit" v-model="companyForm.address" disabled :placeholder="$t('Please select an address on the map')" autocomplete="off" />
              <div v-else class="text line2">{{ companyForm.address }}</div>
            </el-form-item>
            <el-form-item prop="timeZone">
              <div class="title">{{ $t('CompanyEdit.Timezone') }}</div>
              <el-select
                v-model="companyForm.timeZone"
                filterable
                :placeholder="$t('customerInfo.Choose the timezone')"
                autocomplete="off"
                appendToBody
                v-if="isEdit"
              >
                <el-option
                  v-for="item in timezone"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div v-else class="text line2">{{ isFindData(timezone, companyForm.timeZone, 'value', 'key') }}</div>
            </el-form-item>
            <el-form-item prop="website">
              <div class="title">{{ $t('CompanyEdit.Website') }}</div>
              <el-input v-if="isEdit" v-model="companyForm.website" :placeholder="$t('home.Please enter')+$t('CompanyEdit.Website')" autocomplete="off" />
              <a :href="companyWebsite" target="_blank" v-else class="aLink line2">
                {{ companyForm.website }}
              </a>
            </el-form-item>
            <el-form-item style="width: 100%">
              <div class="title">{{ $t('Company Profile') }}</div>
              <el-input v-if="isEdit" type="textarea" v-model="companyForm.companyProfile" :placeholder="$t('Company Profile')" autocomplete="off" />
              <div v-else class="text line2">{{ companyForm.companyProfile }}</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog
      :visible="addDialogVisible"
      width="358px"
      :wrapperClosable="true"
      :title="$t('people.createAccount')"
      :before-close="() => addDialogVisible = !addDialogVisible"
      v-move-outside
    >
      <el-input
        :placeholder="$t('people.customerName')"
        v-model="invitationForm.name"
        style="margin-bottom: 12px;"
      />
      <el-input
        :placeholder="$t('people.Email')"
        v-model="invitationForm.email"
      />
      <div slot="footer">
        <el-button
          class="cancel_button"
          @click="copy"
          size="small"
          style="color: #076F49;">
          {{ $t('people.copyLink').split('，')[1] }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="invitation">
          {{ $t('people.send') }}
        </el-button>
      </div>
    </el-dialog>
    <cropper
      :visible="isCropperVisible"
      :url="logoImage"
      @close="isCropperVisible = false"
      @confirm="pictureHandler"
    />
    <MemberList
      :visible="memberVisible"
      @close="memberVisible = !memberVisible"
      @updateFun="updataFun"
    ></MemberList>
    <seeThaliDetail ref="seeThaliDetail" />
    <mapSelect ref="mapSelect" @updateAddress="updateAddress" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isFindData } from "@/utils/utils.js";
import {
  getOfficeCompany, 
  addJoinCompany,
  createCompany, 
  updateCompany, 
  getCompanyEmployeeList,
  getCompanyList,
  getCompanyByInviteCode,
  getCompanyThaliDetail,
  getTianyanChaSearchData,
  getTianyanChaData
} from '@/api/customer';
import { uploadFile } from '@/api/files';
import { getRegisterLink, sendEmail } from "@/api"

export default {
  components: {
    cropper: () => import('@/components/cropper/cropper.vue'),
    seeThaliDetail: () => import('@/components/seeThaliDetail/index.vue')
  },
  data() {
    return {
      isFindData: isFindData,
      modeType: "",
      loadingUpdating: false,
      isEdit: false,
      addDialogVisible: false,
      memberVisible: false,
      isCropperVisible: false,
      companyLoading: false,
      companyForm: null,
      companySelectId: null,
      companyThaliDetail: null,
      companyPersonnelList: [],
      invitationForm: {
        name: '',
        email: '',
        website: "",
      },
      logoImage: "",
    }
  },
  activated() {
    this.modeType = this.$route.query.type;
    this.getCompany();
  },
  computed: {
    ...mapGetters(['country', 'timezone', 'manageCompany', 'companyId', 'company', "companyType"]),
    memberListComputed() {
      if(this.companyPersonnelList.length > 1) {
        return this.companyPersonnelList.filter((item, index) => index < this.companyPersonnelList.length - 1)
      } else {
        return this.companyPersonnelList;
      }
    },
    companyWebsite() {
      if(/^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/.test(this.companyForm.website)) {
        return this.companyForm.website;
      } else {
        return `https://${this.companyForm.website}`;
      }
    },
    rules() {
      return {
        vat: [
          {
            required: true,
            message: this.$t('home.PleaseinputVAT'),
            trigger: 'blur',
          },
          {
            pattern: /^[A-Za-z0-9]{1,20}$/,
            message: this.$t('home.PleaseinputcorrectVAT'),
            trigger: 'blur',
          },
        ],
        companyCode: [
          {
            required: true,
            message: this.$t('Please enter the invitation code'),
            trigger: 'blur',
          },
        ],
        aliasName: [
          {
            required: true,
            message: this.$t('home.Pleaseinputaliasname'),
            trigger: 'blur',
          },
        ],
        companyLogoId: [
          {
            required: true,
            message: this.$t('home.PleaseuploadCompanylogo'),
            trigger: 'blur',
          },
        ],
        companyType: [
          {
            required: true,
            message: this.$t('home.Pleaseselectcompanytype'),
            trigger: 'blur',
          },
        ],
        contact: [
          {
            required: true,
            message: this.$t('home.Please input contact'),
            trigger: 'blur',
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('home.lease select country'),
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('home.Please input email'),
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('home.Please input name'),
            trigger: 'blur',
          },
        ],
        timeZone: [
          {
            required: true,
            message: this.$t('home.Please select timezone'),
            trigger: 'change',
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('home.Please enter the detailed address'),
          },
        ],
        // website: [{
        //   pattern: /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/,
        //   message: window.vm.$t('people.Please enter the correct official website address'),
        //   trigger: 'blur',
        // }]
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    querySearchCompanyAsync(queryString, cb) {
      if(!queryString) {
        cb([]);
        return;
      }
      getTianyanChaSearchData(queryString).then((data) => {
        cb(data.map(item => ({
          value: item
        })))
      })
    },
    handleCompanySelect(val) {
      getTianyanChaData(val.value).then((data) => {
        this.getCompanyDetail(data);
      })
    },
    mapSelectEvent() {
      this.$refs.mapSelect.open(this.companyForm);
    },
    updateAddress(obj) {
      this.companyForm.address = obj.address;
      this.companyForm.position = obj.addressPosition.join(",");
    },
    seeThaliDetail() {
      this.$refs.seeThaliDetail.open(this.companyThaliDetail);
    },
    hex2Rgba(bgColor, alpha = 1) {
      let color = bgColor.slice(1); // 去掉'#'号
      let rgba = [
        parseInt("0x" + color.slice(0, 2)),
        parseInt("0x" + color.slice(2, 4)),
        parseInt("0x" + color.slice(4, 6)),
        alpha
      ];
      return "rgba(" + rgba.toString() + ")";
    },
    onMoreList() {
      this.memberVisible = true;
    },
    updataFun(e) {
      this.companyPersonnelList = e;
    },
    querySearchAsync(queryString, cb) {
      if(!queryString) {
        cb([]);
        return;
      }
      getCompanyList({
        name: queryString,
      }).then((response) => {
        cb(response.list);
      }).catch(() => {
        cb([]);
      });
    },
    getCodeCompany(e) {
      e.preventDefault();
      if(!this.companyForm.companyCode) return;
      getCompanyByInviteCode(this.companyForm.companyCode).then((data) => {
        this.getCompanyDetail(data);
        this.companySelectId = data.companyId;
      });
    },
    handleSelect(item) {
      getOfficeCompany(item.companyId).then((data) => {
        this.getCompanyDetail(data);
        this.companySelectId = item.companyId;
      });
    },
    //复制邀请链接
    copy() {
      getRegisterLink().then(
        (data) => {
          const theClipboard = navigator.clipboard;
          theClipboard.writeText(data).then(() => {
            this.$message.success(this.$t('home.Copysucceeded'));
          });
        }
      );
    },
    //客户邀请
    invitation() {
      var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
      if(!emailReg.test(this.invitationForm.email)) {
        this.$message.warning(this.$t('login.PleaseinputaSuccessEmail'))
        return;
      }
      sendEmail(this.invitationForm)
      .then(() => {
        this.$message.success(this.$t('home.Invitation sent successfully'));
        this.addDialogVisible = false;
        this.invitationForm = {
          name: '',
          email: ''
        };
      });
    },
    getCompanyList(val) {
      getCompanyEmployeeList(val)
      .then((data) => {
        this.companyPersonnelList = data.memberList;
      });
    },
    //获取公司信息
    getCompany() {
      this.companyLoading = true;
      if(this.$route.query.type == 'see') {
        getOfficeCompany(this.$route.query.companyId).then((data) => {
          this.getCompanyDetail(data);
        });
        return;
      }
      if(this.$route.query.type == 'join') {
        this.isEdit = false;
        this.companyForm = {
          companyCode: "",
          name: "",
          email: "",
          companyType: "",
          vat: "",
          contact: "",
          timeZone: "",
          companyId: "",
          companyLogoId: "",
          address: "",
          position: "",
          aliasName: "",
          website: "",
          country: "",
          companyProfile: ""
        }
        return;
      }
      this.getCompanyDetail(this.company);
    },
    getCompanyDetail(data) {
      if(!data) {
        this.companyForm = {
          companyCode: "",
          name: "",
          email: "",
          companyType: "",
          vat: "",
          contact: "",
          timeZone: "",
          companyId: "",
          companyLogoId: "",
          address: "",
          position: "",
          aliasName: "",
          website: "",
          country: "",
          companyProfile: ""
        }
        this.isEdit = true;
      } else {
        this.companyForm = {
          companyCode: this.companyForm?.companyCode,
          name: data.name,
          email: data.email,
          companyType: data.companyType || this.companyForm.companyType,
          vat: data.vat, 
          contact: data.contact,
          timeZone: data.timeZone,
          companyId: data.companyId || this.companyForm.companyId,
          companyLogoId: data.companyLogoId || this.companyForm.companyLogoId,
          address: data.address,
          position: data.position,
          aliasName: data.aliasName,
          website: data.website,
          country: data.country,
          companyProfile: data.companyProfile
        }
        this.logoImage = data.companyLogoUrl || this.logoImage;
        if(this.modeType != 'add') {
          getCompanyThaliDetail().then((data) => {
            this.companyThaliDetail = data;
          })
        }
        if(this.modeType != 'join' && this.modeType != 'add') {
          this.getCompanyList(this.companyForm.companyId);
        }
      }
      this.companyLoading = false;
    },
    beforeAvatarUpload({ size, type }) {
      const isJPG =
          type === 'image/jpeg' ||
          type === 'image/png' ||
          type === 'image/jpg' ||
          type === 'image/gif';
      const isLt2M = size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(window.vm.$t('customerInfo.Picture must be JPG or PNG or GIF!'));
      }
      if (!isLt2M) {
        this.$message.error(window.vm.$t('customerInfo.Picture size can not exceed 2MB!'));
      }
      return isJPG && isLt2M;
    },
    confirmUploadLogo(event) {
      let files = event.target.files[0]
      if (!this.beforeAvatarUpload(files)) return;
      this.logoImage = URL.createObjectURL(files)
      this.isCropperVisible = true;
      event.target.value = "";
    },
    pictureHandler(file) {
      //只传一个文件的情况
      var data_up = new FormData();
      data_up.append('files', file);
      data_up.append('configCode', 'companyProfile');
      uploadFile(data_up)
        .then((data) => {
          if (!data[0]) return;
          this.companyForm.companyLogoId = data[0];
          this.$nextTick(() => {
            this.$set(this, 'logoImage', URL.createObjectURL(file))
          })
          this.$message.success(this.$t('customerInfo.Upload success'));
          this.isCropperVisible = false;
        })
        .catch((error) => {});
    },
    editCompanyData() {
      if(this.modeType === 'add') {
        if(!this.companyForm.companyLogoId) {
          this.$message.warning(this.$t("home.PleaseuploadCompanylogo"));
          return;
        }
        this.$refs.companyFormRef.validate(async (valid) => {
          if (valid) {
            this.loadingUpdating = true;
            let obj = JSON.parse(JSON.stringify(this.companyForm))
            delete obj.companyCode;
            createCompany(obj)
              .then(() => {
                this.getCompany();
                this.$store.dispatch('user/getInfo');
                this.$store.dispatch('user/getCompany');
                this.isEdit = false;
                this.$message.success(this.$t('people.updateSuccess'));
                this.$router.go(-1);
              })
              .finally(() => (this.loadingUpdating = false));
          }
        });
      } else if(this.modeType === 'join') {
        if(this.companySelectId) {
          if(!this.companyForm.companyCode) {
            this.$message.warning(this.$t('Please enter the invitation code'))
            return;
          }
          this.$prompt('', this.$t('Tips'), {
            confirmButtonText: this.$t("order.Confirms"),
            cancelButtonText: this.$t("order.Cancel"),
            inputType: "textarea",
            inputPlaceholder: this.$t('people.Your note information')
          }).then(({ value }) => {
            addJoinCompany(this.companySelectId, this.companyForm.companyCode, value).then(() => {
              this.$router.push("/index");
              this.isEdit = false;
              this.$message.success(this.$t('people.updateSuccess'));
            });
          }).catch(() => {});
        }
      } else if(!this.isEdit) {
        this.isEdit = true;
      } else {
        this.isEdit = true;
        this.$refs.companyFormRef.validate(async (valid) => {
          if (valid) {
            this.loadingUpdating = true;
            let obj = JSON.parse(JSON.stringify(this.companyForm))
            delete obj.companyCode;
            updateCompany(obj)
              .then(() => {
                // this.$store.dispatch('user/getInfo');
                this.$store.dispatch('user/getCompany', this.companyForm.companyId).then(() => {
                  this.getCompany();
                });
                this.isEdit = false;
                this.$message.success(this.$t('people.updateSuccess'));
              })
              .finally(() => (this.loadingUpdating = false));
          }
        });
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>